import { get, post, put, Delete } from "../axios";

// 市场业绩分类-列表
export const infoMarketClassifyList = (params) =>
  get("/web/info/marketClassify/list", params);

// 市场业绩分类-新增
export const infoMarketClassifyAdd = (params) =>
  post("/web/info/marketClassify/add", params);

// 市场业绩分类-编辑
export const infoMarketClassifyUpdate = (params) =>
  put("/web/info/marketClassify/update", params);

// 市场业绩分类-删除
export const infoMarketClassifyDelete = (id) =>
  Delete("/web/info/marketClassify/delete/" + id, "");
